import React, { useEffect, useContext, useState } from 'react'
import gsap, { Power2 } from 'gsap'
import { GlobalStateContext, GlobalDispatchContext } from '../../containers/GlobalContextProvider'
import styles from './TransitionPlane.module.css'
import Img from 'gatsby-image'

export default function TransitionPlane() {
  const context = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const { transitionState } = context
  const [isPlaneOverlaying, togglePlaneOverlay] = useState(false)
  const [isLoaderShown, toggleLoader] = useState(false)
  let loaderTimeoutReference = React.createRef()

  useEffect(() => {
    switch (transitionState) {
      case 'pageEntering':
        slidePlaneAbove()
        break

      case 'pageExiting':
        return slidePlaneOver()

      default:
        break
    }

    return () => {
      if (loaderTimeoutReference.current) clearTimeout(loaderTimeoutReference.current)
    }
  }, [transitionState])

  function slidePlaneOver() {
    gsap.set('#transition-plane', { y: 0, display: 'block' })
    gsap.to('#transition-plane', {
      y: '-100%',
      duration: 0.6,
      ease: Power2.easeIn,
      onComplete: () => {
        dispatch({ type: 'SET_MENU_OPENED', opened: false })
      }
    })
    togglePlaneOverlay(true)
    // If plane is overlaying for 1500ms, we show loading.
    // This should only happen on extremely slow connections
    loaderTimeoutReference.current = setTimeout(() => {
      toggleLoader(true)
    }, 1000)
  }

  function slidePlaneAbove() {
    if (!isPlaneOverlaying) return
    if (loaderTimeoutReference.current) clearTimeout(loaderTimeoutReference.current)
    toggleLoader(false)
    gsap.to('#transition-plane', {
      y: '-200%',
      duration: 0.6,
      ease: Power2.easeOut,
      onComplete: () => {
        gsap.set('#transition-plane', { y: 0, display: 'none' })
      }
    })
    togglePlaneOverlay(false)
  }

  return (
    <>
      <div id="transition-plane" className={styles.root}>
        <div className={styles.loaderWrap}>
          {isLoaderShown && <div className={styles.loader} />}
        </div>
      </div>

      <div id="trigger-transition-plane" className={styles.root}></div>
    </>
  )
}
