import React, { useEffect, useState, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import LinkTransition from './LinkTransition'
import styles from './NavLinks.module.css'
import { GlobalStateContext, GlobalDispatchContext } from '../../containers/GlobalContextProvider'
import gsap, { Power2 } from 'gsap'

function NavLinks({ locale, show }) {
  const links = [
    {
      sl: {
        to: '/',
        title: 'Domov'
      },
      en: {
        to: '/en/',
        title: 'Home'
      }
    },
    {
      sl: {
        to: '/o-nas/',
        title: 'O nas'
      },
      en: {
        to: '/en/about-us/',
        title: 'About us'
      }
    },
    {
      sl: {
        to: '/o-nas',
        title: 'Storitve',
        data: 'services'
      },
      en: {
        to: '/en/about-us',
        title: 'Services',
        data: 'services'
      }
    },
    {
      sl: {
        to: '/projekti/',
        title: 'Projekti'
      },
      en: {
        to: '/en/projects/',
        title: 'Projects'
      }
    },
    {
      sl: {
        to: '/kontakt/',
        title: 'Kontakt'
      },
      en: {
        to: '/en/contact/',
        title: 'Contact'
      }
    }
  ]
  const context = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const { menuOpened, currentPath } = context
  const [curPath, setCurrentPath] = useState('/')

  // Create ref for each navigation link
  const linkRefs = Array(6)
    .fill()
    .map(() => useRef())

  useEffect(() => {
    if (context.menuOpened) {
      gsap.fromTo(
        linkRefs.map(ref => ref.current),
        { opacity: 0, x: 80, y: 0 },
        { opacity: 1, x: 0, duration: 0.6, stagger: 0.1, delay: 0.3, ease: Power2.easeOut }
      )
    } else {
      gsap.killTweensOf(linkRefs)
    }
  }, [menuOpened])

  useEffect(() => {
    setCurrentPath(currentPath)
  }, [currentPath])

  function exitAnimation(data) {
    if (data === 'services') {
      dispatch({ type: 'SERVICES_MENU_SELECTED', selected: true })
    }
    gsap.to(
      linkRefs.map(ref => ref.current),
      {
        opacity: 0,
        y: -80,
        duration: 0.2,
        stagger: 0.1,
        ease: Power2.easeIn
      }
    )
    dispatch({ type: 'SET_TRANSITION_STATE', state: 'pageExiting' })
  }

  function closeMenu() {
    dispatch({ type: 'SET_MENU_OPENED', opened: false })
  }

  return (
    <nav className={styles.root}>
      <ul className={styles.list}>
        {links.map((link, index) => {
          if (link[locale]?.to && link[locale]?.title)
            return (
              <li className={styles.listItem} key={index} ref={linkRefs[index]}>
                {curPath === link[locale].to ? (
                  <a onClick={closeMenu}>{link[locale].title}</a>
                ) : (
                  <LinkTransition
                    to={link[locale].to}
                    label={link[locale].title}
                    exitTrigger={() => exitAnimation(link[locale].data)}
                  />
                )}
              </li>
            )
        })}
      </ul>
    </nav>
  )
}

NavLinks.propTypes = {
  locale: PropTypes.string.isRequired,
  show: PropTypes.bool
}

export default NavLinks
