import React from 'react'
import PropTypes from 'prop-types'
import styles from './Container.module.css'

const Container = React.forwardRef(
  ({ noPadding, children, fluid, verticalPadding, propStyles }, ref) => {
    return (
      <div
        className={`${styles.root} ${noPadding ? styles.noPadding : ''} ${
          fluid ? styles.fluid : ''
        } ${verticalPadding ? styles.verticalPadding : ''} ${
          verticalPadding === false ? styles.noVerticalPadding : ''
        }`}
        ref={ref}
        style={propStyles}
      >
        {children}
      </div>
    )
  }
)

Container.propTypes = {
  noPadding: PropTypes.bool,
  fluid: PropTypes.bool,
  verticalPadding: PropTypes.bool,
  propStyles: PropTypes.object
}

export default Container
