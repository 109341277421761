import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './Menu.module.css'
import NavLinks from './NavLinks'
import gsap, { Power2 } from 'gsap'
import CompanyDetails from '../CompanyDetails'
import { GlobalStateContext } from '../../containers/GlobalContextProvider'

function Menu({ companyInfo, locale, isOpened }) {
  const context = useContext(GlobalStateContext)
  const menuRef = React.createRef()

  useEffect(() => {
    context.menuOpened ? openMenu() : closeMenu()
  }, [context.menuOpened])

  const [isFullHeight, setFullHeight] = useState(true)
  useEffect(() => {
    setFullHeight(context.viewport.isFullHeight)
  }, [context.viewport])

  function openMenu() {
    if (!menuRef.current) return
    gsap.set(menuRef.current, {
      display: 'flex',
      onComplete: () => {
        gsap.fromTo(
          menuRef.current,
          { x: '100%' },
          {
            x: 0,
            duration: 0.6,
            ease: Power2.easeOut
          }
        )
      }
    })
  }

  function closeMenu() {
    if (!menuRef.current) return
    gsap.to(menuRef.current, {
      x: '100%',
      duration: 0.6,
      ease: Power2.easeOut,
      onComplete: () => {
        if (!menuRef.current) return
        gsap.set(menuRef.current, { display: 'none' })
      }
    })
  }

  return (
    <div className={styles.root} ref={menuRef}>
      <NavLinks locale={locale} show={isOpened} />
      <div className={`${styles.companyInfo} ${!isFullHeight ? styles.extraBottom : ''}`}>
        <CompanyDetails companyInfo={companyInfo} />
      </div>
    </div>
  )
}

Menu.propTypes = {
  locale: PropTypes.string.isRequired,
  companyInfo: PropTypes.object.isRequired
}

export default Menu
