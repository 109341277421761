import React from 'react'

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  locale: 'sl',
  layout: 'normal',
  menuOpened: false,
  tagline: 'Agencija 101',
  headerVariation: 'dark',
  fontsLoaded: false,
  transitionState: 'idle',
  headerShown: false,
  viewport: { isFullHeight: true, heightDiff: 0 },
  nextTransitionPlane: {
    background: null,
    link: null
  },
  prevTransitionPlane: {
    component: null,
    link: null
  },
  servicesMenuSelected: false,
  shouldScrollToProjectList: false,
  customCursorShown: true
}

function reducer(state, action) {
  // console.log('reducer called...', 'current state: ', state, ' action: ', action)

  switch (action.type) {
    case 'SET_PAGE_CONTEXT_DATA': {
      return {
        ...state,
        ...action.data
      }
    }

    case 'SET_VIEWPORT': {
      return {
        ...state,
        viewport: action.viewport
      }
    }

    case 'SERVICES_MENU_SELECTED': {
      return {
        ...state,
        servicesMenuSelected: action.selected
      }
    }

    case 'SCROLL_TO_PROJECT_LIST': {
      return {
        ...state,
        shouldScrollToProjectList: action.scrollToList
      }
    }

    case 'SET_TRANSITION_STATE': {
      return {
        ...state,
        transitionState: action.state
      }
    }

    case 'SET_PREV_TRANSITION_PLANE': {
      return {
        ...state,
        prevTransitionPlane: { component: action.component, link: action.link }
      }
    }

    case 'SET_NEXT_TRANSITION_PLANE': {
      return {
        ...state,
        nextTransitionPlane: { background: action.background, link: action.link }
      }
    }

    case 'SET_NEXT_PAGE_TRANSITION_LINK': {
      return {
        ...state,
        nextTransitionLink: action.link
      }
    }

    case 'SET_LOCALE': {
      return {
        ...state,
        locale: action.locale
      }
    }
    case 'SET_PAGE_LAYOUT': {
      return {
        ...state,
        layout: action.layout
      }
    }
    case 'SET_HEADER_VARIATION': {
      return {
        ...state,
        headerVariation: action.variation
      }
    }

    case 'TOGGLE_CUSTOM_CURSOR': {
      return {
        ...state,
        customCursorShown: action.shown
      }
    }

    case 'TOGGLE_HEADER': {
      return {
        ...state,
        headerShown: action.shown
      }
    }

    case 'SET_MENU_OPENED': {
      return {
        ...state,
        menuOpened: action.opened
      }
    }
    case 'SET_TAGLINE': {
      return {
        ...state,
        tagline: action.tagline
      }
    }
    case 'SET_FONTS_LOADED': {
      return {
        ...state,
        fontsLoaded: true
      }
    }
    default:
      throw new Error('Bad Action Type')
  }
}

const GlobalContextProvider = ({ children, pageContext }) => {
  // We received gatsby pageContext and merge it with initialState
  // pageContext is set up in gatsby.node.js -
  // For example on english pages pageContext will have {locale: "en"}.
  // We will override default initial state of {locale: "sl"} with english one.
  const [state, dispatch] = React.useReducer(reducer, { ...initialState, ...pageContext })
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
