import React from 'react'
import PropTypes from 'prop-types'
import Container from './Container'
import styles from './CompanyDetails.module.css'

function CompanyDetails({ companyInfo }) {
  const {
    name,
    email,
    phone,
    street,
    city,
    zipCode,
    country,
    youtube,
    facebook,
    linkedin,
    instagram
  } = companyInfo
  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.inner}>
          <address className={styles.address}>
            <span className={styles.companyName}>{name}</span>
            <span className={styles.companyAddress}>
              {street}, {zipCode}, {city}
            </span>
          </address>
          <div className={styles.contacts}>
            <a href={`mailto:${email}`} className="active">
              {email}
            </a>
            <a href={`tel:${phone}`} className="active">
              {phone}
            </a>
          </div>
          <div className={styles.socials}>
            <ul>
              {facebook && (
                <li>
                  <a href={facebook} target="_blank" id="social-fb">
                    Facebook
                  </a>
                </li>
              )}
              {instagram && (
                <li>
                  <a href={instagram} target="_blank" id="social-ig">
                    instagram
                  </a>
                </li>
              )}
              {linkedin && (
                <li>
                  <a href={linkedin} target="_blank" id="social-ln">
                    linkedin
                  </a>
                </li>
              )}
              {youtube && (
                <li>
                  <a href={youtube} target="_blank" id="social-yt">
                    youtube
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  )
}

CompanyDetails.propTypes = {
  companyInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
    street: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    zipCode: PropTypes.string,
    country: PropTypes.string,
    youtube: PropTypes.string,
    facebook: PropTypes.string,
    linkedin: PropTypes.string,
    instagram: PropTypes.string
  })
}

export default CompanyDetails
