export function cn (...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function vimeoIdExtractor (url) {
  const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
  return parseInt(url.match(regExp)[5])
}

/**
 * Calls callbackafter user stops resizing
 */
export function debouncedResize (callback) {
  if (typeof window !== 'undefined') {
    let timer

    const functionReference = function () {
      clearTimeout(timer)
      timer = setTimeout(() => {
        callback(window.innerWidth, functionReference)
      }, 250)
    }
    window.addEventListener('resize', functionReference)
    return functionReference
  }

  return null
}

export function clearDebouncedResize (functionReference) {
  window.removeEventListener('resize', functionReference)
}

export function slugify (text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export function filterOutDocsWithoutSlugs ({ slug }) {
  return (slug || {}).current
}

export function buildImageObj (source) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id }
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}
