module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Cherrydorry","Rama Condensed","Rama Regular"],"urls":["/fonts/cherrydorry-export/cherrydorry.css","/fonts/rama/fonts.css"]},"google":{"families":["Roboto Condensed:400,700,300"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M4KTSWZ"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
