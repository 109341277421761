import React, { useEffect, useContext } from 'react'
import { GlobalDispatchContext, GlobalStateContext } from '../containers/GlobalContextProvider'
import { debouncedResize } from '../lib/helpers'
import { isMobile, isTablet } from 'react-device-detect'

/**
 * This helper function is observing the viewport, reporting when 100vh is not equal to window.innerHeight
 * This is important because ios is overlying top address bar and bottom nav bar over the viewport so content is hidden
 * More: https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
 */
export default function ScreenHeightHelper () {
  const dispatch = useContext(GlobalDispatchContext)
  useEffect(() => {
    const divHeight = document.getElementById('100vh-helper').offsetHeight
    const viewportHeight = window.innerHeight
    const diff = Math.abs(divHeight - viewportHeight)

    if (isMobile || isTablet) {
      dispatch({
        type: 'SET_VIEWPORT',
        viewport: {
          isFullHeight: diff < 10,
          heightDiff: diff
        }
      })
    }

    debouncedResize(() => {
      const divHeight = document.getElementById('100vh-helper').offsetHeight
      const viewportHeight = window.innerHeight
      const diff = Math.abs(divHeight - viewportHeight)

      if (isMobile || isTablet) {
        dispatch({
          type: 'SET_VIEWPORT',
          viewport: {
            isFullHeight: diff < 10,
            heightDiff: diff
          }
        })
      }
    })
  }, [])
  return (
    <div
      id='100vh-helper'
      style={{
        height: '100vh',
        margin: 0,
        padding: 0,
        visibility: 'hidden',
        zIndex: -999,
        position: 'absolute',
        opacity: 0,
        left: '-100%',
        pointerEvents: 'none',
        backgroundColor: 'transparent'
      }}
    />
  )
}
