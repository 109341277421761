import React, { useContext, useState } from 'react'
import LinkTransition from './LinkTransition'
import PropTypes from 'prop-types'
import styles from './LangSelector.module.css'
import { GlobalStateContext, GlobalDispatchContext } from '../../containers/GlobalContextProvider'

function LangSelector ({ currentLocale }) {
  /**
   * All pages have its english version path saved in pageContext.{enPath|slPath}
   * That is how you know to which localised url send user.
   * This gets defined in gatsby-node.js
   */
  const context = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  function changeLocale (locale) {
    dispatch({ type: 'SET_LOCALE', locale })
  }

  function exit () {
    dispatch({ type: 'SET_TRANSITION_STATE', state: 'pageExiting' })
  }

  return (
    <div className={`${styles.root} ${context.menuOpened ? styles.shown : ''}`}>
      {currentLocale === 'sl' ? (
        <span className={styles.current}>SL</span>
      ) : (
        <LinkTransition
          to={context.slPath || '/'}
          label='SL'
          exitTrigger={exit}
          entryTrigger={() => changeLocale('sl')}
        />
      )}
      {currentLocale === 'en' ? (
        <span className={styles.current}>EN</span>
      ) : (
        <LinkTransition
          to={context.enPath || '/en/'}
          label='EN'
          exitTrigger={exit}
          entryTrigger={() => changeLocale('en')}
        />
      )}
    </div>
  )
}

LangSelector.propTypes = {
  currentLocale: PropTypes.string.isRequired
}

export default LangSelector
