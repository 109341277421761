import React from 'react'
import GlobalContextProvider from './src/containers/GlobalContextProvider'
import MasterWrapper from './src/containers/MasterWrapper'
import FontListener from './src/containers/FontListener'

export const wrapPageElement = ({ element, props }) => {
  return (
    <GlobalContextProvider pageContext={props.pageContext}>
      <FontListener />
      <MasterWrapper cookieConsent>{element}</MasterWrapper>
    </GlobalContextProvider>
  )
}
