import React, { useContext } from 'react'
import { GlobalDispatchContext } from './GlobalContextProvider'

function FontListener() {
  // WebFontLoader
  const observedNode = document.documentElement // <html></html> element
  const fontsLoadedClass = 'wf-active'
  const dispatch = useContext(GlobalDispatchContext)

  const config = {
    attributes: true,
    attributeFilter: ['class'],
    childList: false,
    characterData: false
  }

  // Callback function to execute when mutations are observed
  const callback = function(mutationsList, observer) {
    for (let mutation of mutationsList) {
      if (mutation.attributeName === 'class') {
        if ([...mutation.target.classList].includes(fontsLoadedClass)) {
          // Fonts are successfully loaded
          dispatch({ type: 'SET_FONTS_LOADED' })
          observer.disconnect()
        }
      }
    }
  }

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback)
  observer.observe(observedNode, config)

  // We render nothing
  return null
}

export default FontListener
