import React, { useContext, useEffect, useState, useRef } from 'react'
import gsap, { Power2 } from 'gsap'
import LinkTransition from './navigation/LinkTransition'
import { PropTypes } from 'prop-types'
import logoSymbol from '../images/logo-symbol.svg'
import Container from '../components/Container'
import LangSelector from './navigation/LangSelector'
import styles from './header.module.css'
import Hamburger from './navigation/Hamburger'
import { GlobalDispatchContext, GlobalStateContext } from '../containers/GlobalContextProvider'
import CookieConsent from '../components/CookieConsent'

const Header = props => {
  const dispatch = useContext(GlobalDispatchContext)
  const { menuOpened, fontsLoaded, headerShown } = useContext(GlobalStateContext)
  const isSwitchingTagline = useRef()

  useEffect(() => {
    if (fontsLoaded) {
      dispatch({ type: 'TOGGLE_HEADER', shown: true })
    }
  }, [fontsLoaded])
  function exiting() {
    dispatch({ type: 'SET_TRANSITION_STATE', state: 'pageExiting' })
  }

  // const [nextTagline, setNextTagline] = useState()
  const [currentTagline, setCurrentTagline] = useState()

  useEffect(() => {
    if (props.tagline !== currentTagline) {
      // Animate nextTagline in
      // After animation ends, switch current with next
      isSwitchingTagline.current = true
      nextTaglineIn().then(() => {
        switchCurrentTagline(props.tagline)
        isSwitchingTagline.current = false
      })
    }
  }, [props.tagline])

  useEffect(() => {
    setCurrentTagline(props.tagline)
  }, [])

  const nextTaglineElement = useRef()
  const currentTaglineElement = useRef()

  function nextTaglineIn() {
    return new Promise((resolve, reject) => {
      gsap.fromTo(
        nextTaglineElement.current,
        { y: '110%' },
        { y: 0, duration: 0.45, ease: Power2.easeInOut }
      )
      gsap.fromTo(
        currentTaglineElement.current,
        { y: 0 },
        { y: '-110%', duration: 0.45, ease: Power2.easeInOut, onComplete: () => resolve() }
      )
    })
  }

  function switchCurrentTagline(nextTagline) {
    setCurrentTagline(nextTagline)
    gsap.set(currentTaglineElement.current, { y: 0 })
    gsap.set(nextTaglineElement.current, { y: '110%' })
  }

  return (
    <header
      className={`${styles.root} ${styles[props.variation]} ${headerShown ? styles.show : ''} ${
        menuOpened ? styles.menuOpened : ''
      }`}
    >
      <div className="cookieConsent">
        <CookieConsent locale={props.locale} />
      </div>
      <Container noPadding>
        <div className={styles.wrapper}>
          <h1 className={styles.branding}>
            <LinkTransition to={props.locale === 'en' ? '/en/' : '/'} exitTrigger={exiting}>
              <img src={logoSymbol} alt="Agencija 101 logo" />
            </LinkTransition>
            <div className={styles.tagline}>
              <span className={styles.taglineText} ref={currentTaglineElement}>
                {currentTagline}
              </span>
              <span className={styles.taglineText} ref={nextTaglineElement}>
                {props.tagline}
              </span>
            </div>
          </h1>

          <div className={styles.actions}>
            <LangSelector currentLocale={props.locale} />
            <Hamburger toggleMenu={props.toggleMenu} isActive={props.menuOpened} />
          </div>
        </div>
      </Container>
    </header>
  )
}

Header.propTypes = {
  locale: PropTypes.string.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  menuOpened: PropTypes.bool.isRequired,
  tagline: PropTypes.string,
  variation: PropTypes.oneOf(['dark', 'light', 'darkGradient']),
  fontsLoaded: PropTypes.bool.isRequired,
  cookieConsent: PropTypes.bool
}

export default Header
