import React from 'react'
import PropTypes from 'prop-types'
import styles from './Hamburger.module.css'

function Hamburger (props) {
  return (
    <div
      className={`${styles.hamburger} ${styles.hamburgerSlider} 
            ${props.isActive ? styles.isActive : ''}`}
      type='button'
      role='button'
      onClick={props.toggleMenu}
    >
      <span className={styles.hamburgerBox}>
        <span className={styles.hamburgerInner} />
      </span>
    </div>
  )
}

Hamburger.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired
}

export default Hamburger
