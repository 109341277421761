import React, { useState } from 'react'
import styles from './CookieConsent.module.css'
import { useCookies } from 'react-cookie'
import Container from './Container'

export default function CookieConsent(props) {
  const cookieName = 'cookie_consent'
  const [cookies, setCookie] = useCookies([cookieName])
  const [closed, setClosed] = useState(false)

  const cs = {
    sl: {
      notice: 'Spletno mesto uporablja piškotke za zagotavljanje boljše uporabniške izkušnje.',
      link: '/piskotki/',
      more: 'Več o tem',
      accept: 'Strinjam se'
    },
    en: {
      notice: 'This website uses cookies to provide a better user experience.',
      link: '/en/cookies/',
      more: 'More',
      accept: 'I agree'
    }
  }

  function onCookieAgreement() {
    setClosed(true)
    setTimeout(() => {
      setCookie(cookieName, cookieName, { path: '/' })
    }, 600)
  }

  if (cookies.cookie_consent) return null

  return (
    <div className={`${styles.root} ${closed ? styles.closed : ''}`}>
      <div className={styles.inner}>
        <Container noPadding>
          <div className={styles.row}>
            <div className={styles.text}>
              <p>
                {cs[props.locale].notice}{' '}
                <a href={cs[props.locale].link}>{cs[props.locale].more}</a>
              </p>
            </div>
            <button className={styles.button} onClick={onCookieAgreement}>
              {cs[props.locale].accept}
            </button>
          </div>
        </Container>
      </div>
    </div>
  )
}
