import React, { useContext, useState, useEffect } from 'react'
import Header from './header'
import { Helmet } from 'react-helmet'
import '../styles/layout.css'
import { GlobalStateContext, GlobalDispatchContext } from '../containers/GlobalContextProvider'
import Menu from './navigation/Menu'
import TransitionPlane from './navigation/TransitionPlane'
import ScreenHeightHelper from '../containers/ScreenHeightHelper'

const Layout = ({ children, companyInfo, cookieConsent }) => {
  const context = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const [stateLayout, updateLayout] = useState('normal')

  const { layout } = context

  useEffect(() => {
    updateLayout(layout)
  }, [layout])

  const toggleMenu = () => {
    dispatch({ type: 'SET_MENU_OPENED', opened: !context.menuOpened })
  }

  return (
    <div className='appLayout'>
      <Helmet htmlAttributes={{ layout: stateLayout, customCursor: "no" }} />
      <ScreenHeightHelper />
      <Header
        toggleMenu={toggleMenu}
        menuOpened={context.menuOpened}
        locale={context.locale}
        tagline={context.tagline}
        variation={context.headerVariation}
        fontsLoaded={context.fontsLoaded}
        cookieConsent={cookieConsent}
      />
      <Menu companyInfo={companyInfo} locale={context.locale} />
      <main>{children}</main>
      <TransitionPlane />
    </div>
  )
}

export default Layout
