import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/layout'

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title {
        _type
        sl
        en
      }
    }
    companyInfo: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
      name
      email
      phone
      street
      zipCode
      city
      country
      youtube
      facebook
      instagram
      linkedin
    }
  }
`

function MasterWrapper(props) {
  return (
    <StaticQuery
      query={query}
      render={data => {
        return (
          <Layout {...props} companyInfo={data.companyInfo}>
            {props.children}
          </Layout>
        )
      }}
    />
  )
}

MasterWrapper.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  })
}

export default MasterWrapper
