import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'
import { GlobalDispatchContext } from '../../containers/GlobalContextProvider'

function exitTrigger(dispatch, props) {
  if (props.onClicked) props.onClicked()
  dispatch({ type: 'SET_TRANSITION_STATE', state: 'pageExiting' })
}

function LinkTransition(props) {
  const dispatch = useContext(GlobalDispatchContext)

  if (props.to.includes("http")) {
    return (
      <a href={props.to} target="_blank">{props.label}</a>
    )
  }

  return (
    <TransitionLink
      to={props.to}
      exit={{
        trigger: () => (props.exitTrigger ? props.exitTrigger() : exitTrigger(dispatch, props)),
        length: props.exitDelay || 0.6
      }}
      entry={{
        delay: props.entryDelay || 0.9,
        trigger: () => props.entryTrigger && props.entryTrigger()
      }}
    >
      {props.label || props.children}
    </TransitionLink>
  )
}

LinkTransition.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.any,
  children: PropTypes.element,
  exitTrigger: PropTypes.func,
  exitDelay: PropTypes.number,
  entryTrigger: PropTypes.func,
  entryDelay: PropTypes.number,
  onClicked: PropTypes.func
}

export default LinkTransition
